import React from 'react'

const DecorationBanner = () => {
  return (
    <section className="cta-section section-padding section-bg">
                <div className="container">
                    <div className="row justify-content-center align-items-center">

                        <div className="col-lg-5 col-12 ms-auto">
                            <h2 className="mb-0">Sustainably <br /> Transforming & <br /> Growing Lives</h2>
                        </div>

                        <div className="col-lg-5 col-12">
                            <a href="https://paystack.com/pay/3teyof8xs7" className="me-4">Make a donation</a>

                            <a href="mailto:info@stgafrica.org" className="custom-btn btn smoothscroll">Partner with Us</a>
                        </div>

                    </div>
                </div>
    </section>
  )
}

export default DecorationBanner