import React from 'react'

const Testimonial = () => {
  return (
    <section className="testimonial-section section-padding section-bg">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12 mx-auto">
                            <h2 className="mb-lg-3">Happy Clients</h2>
                            
                                <div id="testimonial-carousel" className="carousel carousel-fade slide" data-bs-ride="carousel">

                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                           <div className="carousel-caption">
                                                <h4 className="carousel-title">STG Erudite has helped me secure a litigation-free land at Asante Mampong for my Business</h4>

                                                <small className="carousel-name"><span className="carousel-name-title">Hannah</span>, Boss</small>
                                           </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="carousel-caption">
                                                <h4 className="carousel-title">Through STG, I have mentored 10 content creators who are doing very well in the industry.</h4>

                                                <small className="carousel-name"><span className="carousel-name-title">Shadrack</span>, Expert</small>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="carousel-caption">
                                                <h4 className="carousel-title">I am very happy with STG. They have given me the all the support my Business needs to thtive in my community.</h4>

                                                <small className="carousel-name"><span className="carousel-name-title">Jane</span>, Manager</small>
                                            </div>
                                        </div>

                                        <div className="carousel-item">
                                            <div className="carousel-caption">
                                                <h4 className="carousel-title">My market size has increased by 80% since I met STG.</h4>

                                                <small className="carousel-name"><span className="carousel-name-title">Gabriel</span>, Entreprenuer</small>
                                           </div>
                                        </div>

                                          <ol className="carousel-indicators">
                                               <li data-bs-target="#testimonial-carousel" data-bs-slide-to="0" className="active">
                                                    <img src={require('../images/avatar/portrait-beautiful-young-woman-standing-grey-wall.jpg')} className="img-fluid rounded-circle avatar-image" alt="avatar" />
                                               </li>

                                               <li data-bs-target="#testimonial-carousel" data-bs-slide-to="1" className="">
                                                    <img src={require('../images/avatar/portrait-young-redhead-bearded-male.jpg')} className="img-fluid rounded-circle avatar-image" alt="avatar" />
                                               </li>

                                               <li data-bs-target="#testimonial-carousel" data-bs-slide-to="2" className="">
                                                    <img src={require('../images/avatar/pretty-blonde-woman-wearing-white-t-shirt.jpg')} className="img-fluid rounded-circle avatar-image" alt="avatar" />
                                               </li>

                                               <li data-bs-target="#testimonial-carousel" data-bs-slide-to="3" className="">
                                                    <img src={require('../images/avatar/studio-portrait-emotional-happy-funny.jpg')} className="img-fluid rounded-circle avatar-image" alt="avatar" />
                                               </li>
                                          </ol>

                                 </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
  )
}

export default Testimonial