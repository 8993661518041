import React, { useState } from 'react'

const BookSession = () => {

    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phone: '',
        business_location: '',
        booking_date_time: '',
        // booking_time: '',
        about_business: '',
      });

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      } 

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('https://work-api.stgafrica.org/api/book-session/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
        //   const data = await response.json();
          const data = await response.text();
          console.log('Success:', data);
          alert('Application successfully submitted')
          window.location = '/'
        } catch (error) {
          console.error('Error:', error);
          alert(`An error occured, contact Admin for assistance`)
        //   window.location = '/'
        }
        
      };



  return (
    <section className="volunteer-section section-padding" id="section_4">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-12">
                            <img src={require('../images/conference.png')} className="volunteer-image img-fluid" alt="" />

                            <div className="custom-block-body text-center">
                                <h4 className="text-white mt-lg-3 mb-lg-3">Your success is just a call away</h4>

                                <p className="text-white">Are you experiencing a crisis in your Business? We can help you.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-12">
                            <h2 className="text-white mb-4">Book A Free Session With An Expert </h2>

                            <form className="custom-form volunteer-form mb-5 mb-lg-0" onSubmit={handleSubmit}>
                                <h3 className="mb-4">Talk To Us Now</h3>

                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <input type="text" name="fullname" id="volunteer-name" className="form-control"  value={formData.fullname} onChange={handleInputChange} placeholder="Full name" required />
                                    </div>

                                    <div className="col-lg-6 col-12">    
                                        <input type="email" name="email" id="volunteer-email" pattern="[^ @]*@[^ @]*" className="form-control" value={formData.email} onChange={handleInputChange} placeholder="Email" required />
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <input type="text" name="phone" id="volunteer-subject" className="form-control" value={formData.phone} onChange={handleInputChange} placeholder="Phone" required />
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <input type="text" name="business_location" id="volunteer-subject" className="form-control" value={formData.business_location} onChange={handleInputChange} placeholder="Business Location" required />
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <input type='datetime-local' name="booking_date_time" id="volunteer-subject" className="form-control" value={formData.booking_date_time} onChange={handleInputChange} placeholder="Booking Date" required />
                                    </div>

                                    {/* <div className="col-lg-6 col-12">
                                        <input type="text" name="booking_time" id="volunteer-subject" className="form-control" value={formData.booking_time} onChange={handleInputChange} placeholder="Booking Date" required />
                                    </div> */}
                                </div>

                                <textarea name="about_business" rows="3" className="form-control" id="volunteer-message" value={formData.about_business} onChange={handleInputChange} placeholder="About your business"></textarea>

                                <button type="submit" className="form-control">Submit</button>
                            </form>
                        </div>

                        

                    </div>
                </div>
            </section>
  )
}

export default BookSession