import React from 'react'
// import '../css/templatemo-kind-heart-charity.css'
// import '../css/bootstrap-icons.css'
// import '../css/bootstrap.min.css'


const Hero = () => {
  return (
    <section className="hero-section hero-section-full-height">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-lg-12 col-12 p-0">
                            <div id="hero-slide" className="carousel carousel-fade slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="img-container carousel-item active">
                                        <img src={require('../images/slide/a.jpg')}  style={{backgroundPosition: 'center'}} className=" carousel-image img-fluid" alt="..." />
                                        
                                        <div className="hcd-text-overlay">Human-Centered Design</div>
                                        
                                        {/* <!-- <div className="carousel-caption d-flex flex-column justify-content-end">
                                            <h1>be a Kind Heart</h1>
                                            
                                            <p>Professional charity theme based on Bootstrap 5.2.2</p>
                                        </div> --> */}
                                    </div>

                                    <div className="carousel-item">
                                        <img src={require('../images/stg-logo.png')} className="carousel-image img-fluid" alt="..." />
                                        
                                        {/* <!-- <div className="carousel-caption d-flex flex-column justify-content-end">
                                            <h1>Non-profit</h1>  
                                            <p>You can support us to grow more</p>
                                        </div> --> */}
                                    </div>

                                    <div className="carousel-item">
                                        <img src={require('../images/slide/stg-map.jpeg')}  className="carousel-image img-fluid" alt="..." />
                                        
                                        {/* <!-- <div className="carousel-caption d-flex flex-column justify-content-end">
                                            <h3 style="color: hsl(231, 56%, 44%);">Human-centered Design</h2>
                                            
                                            <p>Human-Centered Design</p>
                                        </div> --> */}
                                        <div className="map-text-overlay">Connecting Local Talents <br /> To Global Opportunities    
                                            {/* <!-- <div className="map-text-overlay">.</div> --> */}
                                        </div>
                                    </div>
                                </div>

                                <button className="carousel-control-prev" type="button" data-bs-target="#hero-slide" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>

                                <button className="carousel-control-next" type="button" data-bs-target="#hero-slide" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
  )
}

export default Hero