import React from 'react'
import Navbar from '../components/Navbar'
import CallForExperts from '../components/CallForExperts'

const CallForExpertsPage = () => {  
    return (
    <body>
        <Navbar />
        <CallForExperts />
    </body>    
  )
}

export default CallForExpertsPage