import React from 'react'
import Head from '../components/Head'
import Navbar from '../components/Navbar'
// import BookSession from '../components/BookSession'
import ServiceApply from '../components/service-application.js/ServiceApply'


const ApplyService = () => {
    return (
        <html>
        <Head />
        <body>
        <Navbar />
        <ServiceApply />
        
        </body>
        </html>
        
        // <html>
        // <Head />
        // <body>
    
        // <Header />
        // <Navbar/>
        // <Hero />
        // <CoreValues />
        // <AboutUs />
        // <ManagerMessage />
        // <DecorationBanner />
        // <BookSession />
        // <Services />
        // <CallForExperts />
        // <Blog />
        // <Testimonial />
        // <Contact />
        // <Footer />
        // </body>
    
        // </html>
        
    
      )
}

export default ApplyService