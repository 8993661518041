import React, {useState} from 'react'

const Contact = () => {

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        message: '',
      });

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      } 

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await fetch('https://work-api.stgafrica.org/api/contact/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
        //   const data = await response.json();
          const data = await response.text();
          console.log('Success:', data);
          alert('Application successfully submitted')
          window.location = '/'
        } catch (error) {
          console.error('Error:', error);
          alert(`An error occured, contact Admin for assistance`)
        //   window.location = '/'
        }
        
      };



  return (
    <section className="contact-section section-padding" id="section_6">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-4 col-12 ms-auto mb-5 mb-lg-0">
                            <div className="contact-info-wrap">
                                <h2>Get in touch</h2>

                                <div className="contact-image-wrap d-flex flex-wrap">
                                    <img src={require('../images/stg-logo.png')}  className="img-fluid avatar-image" alt="" />

                                    <div className="d-flex flex-column justify-content-center ms-3">
                                        <p className="mb-0">Akua Benewaa Yeboa A</p>
                                        <p className="mb-0"><strong>HR & Office Manager</strong></p>
                                    </div>
                                </div>

                                <div className="contact-info">
                                    <h5 className="mb-3">Contact Infomation</h5>

                                    <p className="d-flex mb-2">
                                        <i className="bi-geo-alt me-2"></i>
                                        Office Location : Ejisu - Ashanti Region, Ghana
                                    </p>

                                    <p className="d-flex mb-2">
                                        <i className="bi-telephone me-2"></i>

                                        <a href="tel: 120-240-9600">
                                            {/* <!-- 024-202-9161 --> */}
                                            055-980-1548
                                        </a>
                                    </p>

                                    <p className="d-flex">
                                        <i className="bi-envelope me-2"></i>

                                        <a href="mailto:info@stgafrica.org">
                                            info@stgafrica.org
                                        </a>
                                    </p>

                                    <a href="#" className="custom-btn btn mt-3">Get Direction</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-12 mx-auto">
                            <form className="custom-form contact-form" onSubmit={handleSubmit}>
                                <h2>Contact form</h2>

                                <p className="mb-4">Or, you can just send an email:
                                    <a href="#">info@stgafrica.org</a>
                                </p>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <input type="text" name="firstname" id="first-name" className="form-control" value={formData.firstname} onChange={handleInputChange} placeholder="First name" required />
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <input type="text" name="lastname" id="last-name" className="form-control" value={formData.lastname} onChange={handleInputChange} placeholder="Last name" required />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control" value={formData.email} onChange={handleInputChange} placeholder="Email" required />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <input type="tel" name="phone" id="phone" className="form-control" value={formData.phone} onChange={handleInputChange} placeholder="Phone" required />
                                    </div>
                                </div>

                                <textarea name="message" rows="5" className="form-control" id="message" value={formData.message} onChange={handleInputChange} placeholder="How can we help you?"></textarea>

                                <button type="submit" className="form-control">Send Message</button>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
  )
}

export default Contact