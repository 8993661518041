import React from 'react'
import Header from '../components/Header'
import Head from '../components/Head'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom'
import inclusion_pdf from '../documents/inclusion_policy.pdf'
import psea_pdf from '../documents/psea_policy.pdf'

const Policies = () => {
  return (
   
      
    <body>
    <Head />

    <Header />
    <Navbar/>

        <h3 className='text-center'>STG Erudite Policies</h3>
    <div  className='d-flex my-5 justify-content-center align-items-center'>
        <ul>
            <li><a href={inclusion_pdf}>Inclusion & Safeguarding Policy - STG Erudite</a></li>
            <li><a href={psea_pdf}>PSEA Policy - STG Erudite</a></li>
        </ul>

    </div>
    
    

    

    {/* <Link to={psea_pdf} >Hello</Link> */}
    </body>
   
  )
}

export default Policies