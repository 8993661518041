import React from 'react'

const ManagerMessage = () => {
  return (
    <section className="about-section section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-5 col-12">
                            <img src={require('../images/madam.jpg')} className="about-image ms-lg-auto bg-light shadow-lg img-fluid" alt="" />
                        </div>

                        <div className="col-lg-5 col-md-7 col-12">
                            <div className="custom-text-block">
                                <h2 className="mb-0">Akua Benewaa Yeboa</h2>

                                <p className="text-muted mb-lg-4 mb-md-4">Co-Founding Partner</p>

                                <p>STG Erudite is an expert consortium of grassroot mentors, trainers, coaches & enterprise support groups, that focuses on local communities, youth, women and deprived groups for  Sustainability and Transformational Growth (STG) </p>

                                <ul className="social-icon mt-4">
                                    <li className="social-icon-item">
                                        <a href="#" className="social-icon-link bi-twitter"></a>
                                    </li>

                                    <li className="social-icon-item">
                                        <a href="#" className="social-icon-link bi-facebook"></a>
                                    </li>

                                    <li className="social-icon-item">
                                        <a href="#" className="social-icon-link bi-instagram"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
  )
}

export default ManagerMessage