import React from 'react'
// import ''

const CoreValues = () => {
  return (
    <section className="section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-10 col-12 text-center mx-auto">
                            <h2 className="mb-5">Our Core-Values</h2>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                            <div className="featured-block d-flex justify-content-center align-items-center">
                                <a href="/" className="d-block">
                                    {/* <img src={'../images/icons/hands.png'} className="featured-block-image img-fluid" alt="" /> */}
                                    <img src={require('../images/icons/hands.png')} className="featured-block-image img-fluid" alt="" />

                                    <p className="featured-block-text"><strong>Sustainability</strong></p>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
                            <div className="featured-block d-flex justify-content-center align-items-center">
                                <a href="/" className="d-block">
                                    <img src={require ('../images/icons/heart.png')} className="featured-block-image img-fluid" alt="" />

                                    <p className="featured-block-text"><strong>Transformation</strong></p>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
                            <div className="featured-block d-flex justify-content-center align-items-center">
                                <a href="/" className="d-block">
                                    <img src={require ('../images/icons/receive.png')} className="featured-block-image img-fluid" alt="" />

                                    <p className="featured-block-text"><strong>Holistic Growth</strong></p>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                            <div className="featured-block d-flex justify-content-center align-items-center">
                                <a href="/" className="d-block">
                                    <img src={require ('../images/icons/scholarship.png')} className="featured-block-image img-fluid" alt="" />

                                    <p className="featured-block-text"><strong>Ethical Development</strong></p>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
  )
}

export default CoreValues