import React from 'react'
import Header from '../components/Header'
import Head from '../components/Head'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import CoreValues from '../components/CoreValues'
import AboutUs from '../components/AboutUs'
import ManagerMessage from '../components/ManagerMessage'
import DecorationBanner from '../components/DecorationBanner'
import BookSession from '../components/BookSession'
import Services from '../components/Services'
import CallForExperts from '../components/CallForExperts'
// import Blog from '../components/Blog'
import Testimonial from '../components/Testimonial'
import Contact from '../components/Contact'
import Footer from '../components/Footer'


const Home = () => {
  return (
    <html>
    <Head />
    <body>

    <Header />
    <Navbar/>
    <Hero />
    <CoreValues />
    <AboutUs />
    <ManagerMessage />
    <DecorationBanner />
    <BookSession />
    <Services />
    <CallForExperts />
    {/* <Blog /> */}
    <Testimonial />
    <Contact />
    <Footer />
    </body>

    </html>
    

  )
}

export default Home