import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
import { BrowserRouter as Router, Routes,  Route } from 'react-router-dom';
import Home from './pages/Home';
import Donate from './pages/Donate';
import reportWebVitals from './reportWebVitals';
import ApplyService from './pages/ApplyService';
import CallForExpertsPage from './pages/CallForExpertsPage';
import Policies from './pages/Policies';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>      
        <Routes>       
          <Route path='/' element={<Home />} />
          <Route path='/donate' element={<Donate />} />   
          <Route path='/apply-service/:service-title' element={<ApplyService />} />  
          <Route path = '/call-for-experts' element={<CallForExpertsPage />} />
          <Route path = '/policies' element={<Policies />} />
        </Routes>      
    </React.StrictMode>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
