import React from 'react'


const AboutUs = () => {
  return (
    <section className="section-padding section-bg" id="section_2">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-12 mb-5 mb-lg-0">
                            <img src={require('../images/happy-team.jpg')} style={{ position: 'relative', height: '100%', width: '100%', important: true }} className="custom-text-box-image img-fluid" alt="" />
                        </div>

                        <div className="col-lg-6 col-12">
                            <div className="custom-text-box">
                                <h2 className="mb-2">About Us</h2>

                                <h5 className="mb-3">STG Erudite Foundation Africa & Associates</h5>

                                <p className="mb-0">
                                    STG Erudite Foundation Africa & Associates was originally birth as a Local  Project Initiative to ideate , accelerate and support local indigenous start up &  talents development in Ghana/Africa. We have human centred - engaging  expert source   consultants, who have varied experience and worked with a number of  local innovation hubs, community - based organizations, Developmental  Organisations and understand the global dynamics of bottom - Up challenges local talents face, as we develop  tailored sustainable innovative solutions, to address these within the  African innovation and enterpreneurship ecosystem.
                                </p>
                                <hr/>
                                <p>
                                    The key focus is on Mindset and Skillset Talent nurturing from foundational development to transactional and  transformational development. We develop Individuals and 
                                    entities from original ideas into sustainable start ups and scale - ups in Africa, ensuring  essential collective community and family  enterprise support systems are built around the individuals and innovations, engaging key ecosystem Stakeholders for scalability and accelerator growth. Having Team leads with  over 20 years combined  experience working with a number of Community - based Groups, NGOs, CSOs, Youth Groups, Women, Local PwD Groups, Innovation & Entrepreneurship accelerator projects Centres. We  have carefully and strategically created such innovation - driven erudites within the ecosystem in a project based consortium solely  to Sustainably Transform & Grow (STG) more Lives and Local  Communities in Ghana/ Africa , partnering with key stakeholders who share the vision and mission of a transformed and sustained holistic development of Lives and Communities in Africa.                                    
                                </p>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="custom-text-box mb-lg-0">
                                        <h5 className="mb-3">Our Mission</h5>

                                        <p>
                                            To sustain and transform lives and local communities in Africa through mentoring, coaching, training and local talent enterprise support.
                                        </p>

                                        {/* <!-- <ul className="custom-list mt-2">
                                            <li className="custom-list-item d-flex">
                                                <i className="bi-check custom-text-box-icon me-2"></i>
                                                Charity Theme
                                            </li>

                                            <li className="custom-list-item d-flex">
                                                <i className="bi-check custom-text-box-icon me-2"></i>
                                                Semantic HTML
                                            </li>
                                        </ul> --> */}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="custom-text-box mb-lg-0">
                                        <h5 className="mb-3">Our Vision</h5>

                                        <p>
                                            Sustainability of Communities through  Lives Transformation 
                                        </p>
                                        <br />
                                        <br />
                                        <br />

                                        {/* <!-- <ul className="custom-list mt-2">
                                            <li className="custom-list-item d-flex">
                                                <i className="bi-check custom-text-box-icon me-2"></i>
                                                Charity Theme
                                            </li>

                                            <li className="custom-list-item d-flex">
                                                <i className="bi-check custom-text-box-icon me-2"></i>
                                                Semantic HTML
                                            </li>
                                        </ul> --> */}
                                    </div>
                                    {/* <!-- <div className="custom-text-box d-flex flex-wrap d-lg-block mb-lg-0">
                                        <div className="counter-thumb"> 
                                            <div className="d-flex">
                                                <span className="counter-number" data-from="1" data-to="2009" data-speed="1000"></span>
                                                <span className="counter-number-text"></span>
                                            </div>

                                            <span className="counter-text">Founded</span>
                                        </div> 

                                        <div className="counter-thumb mt-4"> 
                                            <div className="d-flex">
                                                <span className="counter-number" data-from="1" data-to="120" data-speed="1000"></span>
                                                <span className="counter-number-text">B</span>
                                            </div>

                                            <span className="counter-text">Donations</span>
                                        </div> 
                                    </div> --> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

  )
}

export default AboutUs