import React from 'react'

const Services = () => {    

  return (
    <section className="section-padding" id="section_3">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12 text-center mb-4">
                            <h2>Our Services</h2>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                            <div className="custom-block-wrap">
                                <img src={require('../images/causes/technical.jpg')} className="custom-block-image img-fluid" alt="" />

                                <div className="custom-block">
                                    <div className="custom-block-body">
                                        <h5 className="mb-3">Technical Lead For Projects Direction, Innovation Design & Implementation</h5>

                                        {/* <!-- <p>Lorem Ipsum dolor sit amet, consectetur adipsicing kengan omeg kohm tokito</p> --> */}

                                        <div className="progress mt-4">
                                            <div className="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div className="d-flex align-items-center my-2">
                                            {/* <!-- <p className="mb-0">
                                                <strong>Raised:</strong>
                                                $18,500
                                            </p> --> */}

                                            <p className="ms-auto mb-0">
                                                <strong>Price:</strong>
                                                $150 - $300
                                            </p>
                                        </div>
                                    </div>

                                    <a href="apply-service/technical" className="custom-btn ">Apply</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                            <div className="custom-block-wrap">
                                <img src={require('../images/causes/finance.jpg')} className="custom-block-image img-fluid" alt="" />

                                <div className="custom-block">
                                    <div className="custom-block-body">
                                        <h5 className="mb-3">Finance , Procurement, Administration & Enterprise Development</h5>

                                        {/* <!-- <p>Sed leo nisl, posuere at molestie ac, suscipit auctor mauris. Etiam quis metus tempor</p> --> */}

                                        <div className="progress mt-4">
                                            <div className="progress-bar w-50" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div className="d-flex align-items-center my-2">
                                            {/* <!-- <p className="mb-0">
                                                <strong>Raised:</strong>
                                                $27,600
                                            </p> --> */}

                                            <p className="ms-auto mb-0">
                                                <strong>Price:</strong>
                                                $70 - $150
                                            </p>
                                        </div>
                                    </div>

                                    <a href="apply-service/finance" className="custom-btn btn">Apply</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="custom-block-wrap">
                                <img src={require('../images/causes/legal.jpg')} className="custom-block-image img-fluid" alt="" />

                                <div className="custom-block">
                                    <div className="custom-block-body">
                                        <h5 className="mb-3">Legal, Intellectual Property & Contract Guide for Registration</h5>

                                        {/* <!-- <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus</p> --> */}

                                        <div className="progress mt-4">
                                            <div className="progress-bar w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div className="d-flex align-items-center my-2">
                                            {/* <!-- <p className="mb-0">
                                                <strong>Raised:</strong>
                                                $84,600
                                            </p> --> */}

                                            <p className="ms-auto mb-0">
                                                <strong>Price:</strong>
                                                $70 - $150
                                            </p>
                                        </div>
                                    </div>

                                    <a href="apply-service/legal" className="custom-btn btn">Apply</a>
                                </div>
                            </div>
                        </div>

                   

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="custom-block-wrap">
                                <img src={require('../images/causes/operations.jpg')} className="custom-block-image img-fluid" alt="" />

                                <div className="custom-block">
                                    <div className="custom-block-body">
                                        <h5 className="mb-3">Operations / Field Assessment Management</h5>

                                        {/* <!-- <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus</p> --> */}

                                        <div className="progress mt-4">
                                            <div className="progress-bar w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div className="d-flex align-items-center my-2">
                                            {/* <!-- <p className="mb-0">
                                                <strong>Raised:</strong>
                                                $84,600
                                            </p> --> */}

                                            <p className="ms-auto mb-0">
                                                <strong>Price:</strong>
                                                $70 - $150
                                            </p>
                                        </div>
                                    </div>

                                    <a href="apply-service/operations" className="custom-btn btn">Apply</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="custom-block-wrap">
                                <img src={require('../images/causes/merbli.jpg')}  className="custom-block-image img-fluid" alt="" />

                                <div className="custom-block">
                                    <div className="custom-block-body">
                                        <h5 className="mb-3">Monitoring, Evaluation, Research & Behavior Learning Insight</h5>

                                        {/* <!-- <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus</p> --> */}

                                        <div className="progress mt-4">
                                            <div className="progress-bar w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div className="d-flex align-items-center my-2">
                                            {/* <!-- <p className="mb-0">
                                                <strong>Raised:</strong>
                                                $84,600
                                            </p> --> */}

                                            <p className="ms-auto mb-0">
                                                <strong>Price:</strong>
                                                $70 - $150
                                            </p>
                                        </div>
                                    </div>

                                    <a href="apply-service/merbli" className="custom-btn btn">Apply</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="custom-block-wrap">
                                <img src={require('../images/causes/coaching.jpg')} className="custom-block-image img-fluid" alt="" />

                                <div className="custom-block">
                                    <div className="custom-block-body">
                                        <h5 className="mb-3">Mentorship, Coaching & Training Services</h5>

                                        {/* <!-- <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus</p> --> */}

                                        <div className="progress mt-4">
                                            <div className="progress-bar w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div className="d-flex align-items-center my-2">
                                            {/* <!-- <p className="mb-0">
                                                <strong>Raised:</strong>
                                                $84,600
                                            </p> --> */}

                                            <p className="ms-auto mb-0">
                                                <strong>Price:</strong>
                                                $50 - $100
                                            </p>
                                        </div>
                                    </div>

                                    <a href="apply-service/coaching" className="custom-btn btn">Apply</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="custom-block-wrap">
                                <img src={require('../images/causes/content.jpg')} className="custom-block-image img-fluid" alt="" /> 

                                <div className="custom-block">
                                    <div className="custom-block-body">
                                        <h5 className="mb-3">Video & Content Creation </h5>

                                        {/* <!-- <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus</p> --> */}

                                        <div className="progress mt-4">
                                            <div className="progress-bar w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div className="d-flex align-items-center my-2">
                                            {/* <!-- <p className="mb-0">
                                                <strong>Raised:</strong>
                                                $84,600
                                            </p> --> */}

                                            <p className="ms-auto mb-0">
                                                <strong>Price:</strong>
                                                $70 - $150
                                            </p>
                                        </div>
                                    </div>

                                    <a href="apply-service/content" className="custom-btn btn">Apply</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="custom-block-wrap">
                                <img src={require('../images/causes/volunteer.jpg')} className="custom-block-image img-fluid" alt="" />

                                <div className="custom-block">
                                    <div className="custom-block-body">
                                        <h5 className="mb-3">Volunteers & Solution Scouts Services </h5>

                                        {/* <!-- <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus</p> --> */}

                                        <div className="progress mt-4">
                                            <div className="progress-bar w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>

                                        <div className="d-flex align-items-center my-2">
                                            {/* <!-- <p className="mb-0">
                                                <strong>Raised:</strong>
                                                $84,600
                                            </p> --> */}

                                            <p className="ms-auto mb-0">
                                                <strong>Price:</strong>
                                                $30 - $50
                                            </p>
                                        </div>
                                    </div>

                                    <a href="apply-service/volunteer" className="custom-btn btn">Apply</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
  )
}

export default Services