import React from 'react'
// import '../css/templatemo-kind-heart-charity.css'
// import '../css/bootstrap-icons.css'
// import '../css/bootstrap.min.css'

const Head = () => {
  return (
    <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="description" content="" />
        <meta name="author" content="" />

        <title>STG Erudite Foundation</title>

        {/* CSS FILES */}        
        <link href="css/bootstrap.min.css" rel="stylesheet" />

        <link href="css/bootstrap-icons.css" rel="stylesheet" />

        <link href="css/templatemo-kind-heart-charity.css" rel="stylesheet" />
        
        {/* Favicons */}
        <link href="images/stg-logo.png" rel="icon" />
        <link href="images/stg-logo.png" rel="apple-touch-icon" />


    </head>
  )
}

export default Head