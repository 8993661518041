import React from 'react'
// import '../css/templatemo-kind-heart-charity.css'
// import '../css/bootstrap-icons.css'
// import '../css/bootstrap.min.css'

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-light shadow-lg">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img id="navlogo" src={require('../images/stg-logo.png')} className="logo img-fluid" alt="Kind Heart Charity" />
                    <span >
                        STG Erudite
                        <small id="tagline">Local African Talent Development Agency</small>
                    </span>
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="/">Home</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_2">About</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_3">Services</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_4">Experts</a>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link click-scroll dropdown-toggle" href="#section_5" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">News</a>

                            <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
                                <li><a className="dropdown-item" href="/">News Listing</a></li>

                                <li><a className="dropdown-item" href="/">News Detail</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link click-scroll" href="#section_6">Contact</a>
                        </li>

                        <li className="nav-item ms-3">
                            <a className="nav-link custom-btn custom-border-btn btn" href="https://paystack.com/pay/3teyof8xs7">Donate</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
  )
}

export default Navbar