import React, { useState } from 'react'

const CallForExperts = () => {

    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        area_of_expertise: '',
        phone: '',
        about_yourself: '',
      });

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
      } 

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const link = 'https://work-api.stgafrica.org/api/call-for-experts/'
          // const link = 'http://127.0.0.1:8000/api/call-for-experts/'
          const response = await fetch(link, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
        //   const data = await response.json();
          const data = await response.text();
          console.log('Success:', data);
          alert('Application successfully submitted')
          window.location = '/'
        } catch (error) {
          console.error('Error:', error);
          alert(`An error occured, contact Admin for assistance`)
        //   window.location = '/'
        }
        
      };

  return (
    <section className="volunteer-section section-padding" id="section_4">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-12">
                            <img  src={require('../images/mentors.jpg')} className="volunteer-image img-fluid" alt="" />

                            <div className="custom-block-body text-center">
                                <h4 className="text-white mt-lg-3 mb-lg-3">About Mentors & Experts</h4>

                                <p className="text-white">Individuals who have gained some experience practising in a chosen field - whether formal or informal</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-12">
                            <h2 className="text-white mb-4">Call for Mentors & Experts</h2>

                            <form className="custom-form volunteer-form mb-5 mb-lg-0" onSubmit={handleSubmit}>
                                <h3 className="mb-4">Become a Mentor / Expert today</h3>

                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <input type="text" name="fullname" id="volunteer-name" className="form-control" value={formData.fullname} onChange={handleInputChange} placeholder="Full name" required />
                                    </div>

                                    <div className="col-lg-6 col-12">    
                                        <input type="email" name="email" id="volunteer-email" pattern="[^ @]*@[^ @]*" className="form-control" value={formData.email} onChange={handleInputChange} placeholder="Email" required />
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <input type="text" name="area_of_expertise" id="volunteer-subject" className="form-control" value={formData.area_of_expertise} onChange={handleInputChange} placeholder="Area of Expertise" required />
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <input type="text" name="phone" id="volunteer-subject" className="form-control" value={formData.phone} onChange={handleInputChange} placeholder="Phone" required />
                                    </div>
                                </div>

                                <textarea name="about_yourself" rows="3" className="form-control" id="volunteer-message" value={formData.about_yourself} onChange={handleInputChange} placeholder="About yourself"></textarea>

                                <button type="submit" className="form-control">Submit</button>
                            </form>
                        </div>

                        

                    </div>
                </div>
            </section>
  )
}

export default CallForExperts