import React from 'react'

const Footer = () => {
  return (
    <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-12 mb-4">
                        <img src={require('../images/stg-logo.png')} className="logo img-fluid" alt="" />
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mb-4">
                        <h5 className="site-footer-title mb-3">Quick Links</h5>

                        <ul className="footer-menu">
                            <li className="footer-menu-item"><a href="#" className="footer-menu-link">Home</a></li>

                            <li className="footer-menu-item"><a href="#" className="footer-menu-link">About</a></li>

                            <li className="footer-menu-item"><a href="#" className="footer-menu-link">Policies</a></li>

                            <li className="footer-menu-item"><a href="#" className="footer-menu-link">Services</a></li>

                            <li className="footer-menu-item"><a href="#" className="footer-menu-link">Become an Expert</a></li>

                            <li className="footer-menu-item"><a href="mailto:info@stgafrica.org" className="footer-menu-link">Partner with us</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-6 col-12 mx-auto">
                        <h5 className="site-footer-title mb-3">Contact Infomation</h5>

                        <p className="text-white d-flex mb-2">
                            <i className="bi-telephone me-2"></i>

                            <a href="tel: 120-240-9600" className="site-footer-link">
                                {/* <!-- 024-202-9161 --> */}
                                055-980-1548
                            </a>
                        </p>

                        <p className="text-white d-flex">
                            <i className="bi-envelope me-2"></i>

                            <a href="mailto:info@stgafrica.org" className="site-footer-link">
                                info@stgafrica.org
                            </a>
                        </p>

                        <p className="text-white d-flex mt-3">
                            <i className="bi-geo-alt me-2"></i>
                            Office Location : Ejisu - Ashanti Region, Ghana
                        </p>

                        <a href="#" className="custom-btn btn mt-3">Get Direction</a>
                    </div>
                </div>
            </div>

            <div className="site-footer-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6 col-md-7 col-12">
                            <p className="copyright-text mb-0"><a href="#">Designed by PHG Techhub</a>  <a target="_blank">PHGEx</a></p>
                            {/* <!-- <p className="copyright-text mb-0">Copyright © 2036 <a href="#">Kind Heart</a> Charity Org. */}
                                {/* Design: <a href="https://templatemo.com" target="_blank">TemplateMo</a></p> --> */}
                        </div>
                        
                        <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                            <ul className="social-icon">
                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link bi-twitter"></a>
                                </li>

                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link bi-facebook"></a>
                                </li>

                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link bi-instagram"></a>
                                </li>

                                <li className="social-icon-item">
                                    <a href="#" className="social-icon-link bi-linkedin"></a>
                                </li>

                                <li className="social-icon-item">
                                    <a href="https://youtube.com/templatemo" className="social-icon-link bi-youtube"></a>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </footer>
  )
}

export default Footer